<template>
    <uw-page :title="year + '年 工时统计 / P'">

        <!-- 功能 -->
        <template slot="header-suffix">
            <el-button @click="TableGet()" type="success">刷新</el-button>
            <el-button type="primary" @click="$refs.xTable.print()" >打印</el-button>
            <el-button type="primary" @click="$refs.xTable.openExport()" >导出</el-button>
        </template>

        <!-- 检索 -->
        <template slot="search">
            <el-form-item label="用户查询">
                <users-select v-model="search.users_id" multiple @onChange="TableGet" />
            </el-form-item>
            <el-form-item label="日期查询">
                <el-date-picker v-model="search.year" clearable @change="DataChange" type="year" value-format="yyyy" />
            </el-form-item>
        </template>

        <!-- 数据 -->
        <vxe-table
            ref="xTable"
            height="100%"
            show-footer
            :data="users"
            :loading="loading"
            :footer-method="TableFooter"
            :export-config="{}"
            :print-config="{}"
            @sort-change="TableSort">

            <!-- 用户 -->
            <vxe-column min-width="120" field="name" title="成员" fixed="left">
                <template #default="{ row }">
                    <div style="display: flex; align-items: center; justify-content: flex-start;">
                        <el-avatar style="margin-right: 5px;" :size="26" :src="row.avatar">{{ row.name[0] }}</el-avatar>
                        <el-link type="primary" @click="$refs.employeeDrawer.Open(row.id)">{{  row.name }}</el-link>
                    </div>
                </template>
            </vxe-column>

            <!-- 登记工时 -->
            <vxe-column min-width="120" field="sum" title="登记工时/P" fixed="left" align="right" sortable>
                <template #default="{ row }">
                    {{ row.sum }}
                </template>
            </vxe-column>

            <vxe-column min-width="80" :field="item" align="right" v-for="item in Object.keys(month)" :key="item" :title="item + '月'" sortable>
                <template #default="{ row }">
                    <el-link :underline="false" @click="$refs.workingDrawer.open({ users_id: row.id, year: year, month: item })">
                        {{ row.month[item] ?? '-' }}
                    </el-link>
                </template>
            </vxe-column>

        </vxe-table>

        <!-- 组件 -->
        <pm-working-drawer ref="workingDrawer" />
        <hrm-employee-drawer ref="employeeDrawer" />
            
    </uw-page>
</template>

<script>
export default {
    data () {
        return {
            loading: false,
            
            users: [],
            sum: 0,
            month: [],
            year: null,

            // 检索
            search: {
                users_id: null,
                year: null,
            },

            // 排序
            order: {
                method: 'asc',
                column: 'sum'
            },
        }
    },

    mounted () {
        this.TableGet()
    },

    methods: {
        /**
        |--------------------------------------------------
        | 数据获取
        |--------------------------------------------------
        |
        */
        
        TableGet() {
            this.loading = true
            this.$http.post('/9api/pm/working/search-year', { order: this.order, search: this.search }).then((rps) => {
                this.users = rps.data.users
                this.sum = rps.data.sum
                this.month = rps.data.month
                this.year = rps.data.year
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 时间变更
        |--------------------------------------------------
        |
        */
        
        DataChange() {
            this.order.column = 'sum'
            this.TableGet()
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableGet()
        },

        /**
        |--------------------------------------------------
        | 表尾数据
        |--------------------------------------------------
        |
        */

        TableFooter() {
            return [
                ['合计', this.sum, ...Object.values(this.month) ],
            ]
        },
    }
}
</script>